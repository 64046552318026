import dayjs from "dayjs";
dayjs.extend(require('dayjs/plugin/customParseFormat'))

export const SHOWTIME_FORMAT_DISPLAY = 'MMM D, YYYY h:mm A'
export const SHOWTIME_FORMAT_STORED = 'YYYY-MM-DDTHH:mm'

export const isDisplayShowtime = (date: string) => {
    return dayjs(date, SHOWTIME_FORMAT_DISPLAY).isValid()
}
export const toShowtime = (showtime: string): string => {
    if (!showtime) return "";
    const date = dayjs(showtime, SHOWTIME_FORMAT_STORED)
    if (date.isValid()) {
        return date.format(SHOWTIME_FORMAT_DISPLAY)
    }
    return showtime
}

export const toShowtimeEventPageForReactDatePicker = (showtime: string): string => {
    if (!showtime) return "";
    const date = dayjs(showtime)
    return date.format(SHOWTIME_FORMAT_DISPLAY)
}


export const formatDate = (fullDateString: string): string => {
    return dayjs(fullDateString).format(SHOWTIME_FORMAT_DISPLAY)
}

export const formatDateStored = (fullDateString: string): string => {
    return dayjs(fullDateString).format(SHOWTIME_FORMAT_STORED)
}

export const formatOffsetDate = (offset: number): string => {
    return dayjs.unix(offset).format(SHOWTIME_FORMAT_DISPLAY)
}