import {ProductVariant} from "@medusajs/medusa";
import dayjs from "dayjs";
import {SHOWTIME_FORMAT_DISPLAY, SHOWTIME_FORMAT_STORED} from "../utils/date";
import {Product} from "../models/models";
dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/isSameOrAfter'))

export function productTransformer(product: Product): Product {
    const today = dayjs()
    const activeVariants = product.variants.filter((v) => {
        const showtime = getShowtime(product, v)
        const eventDate = dayjs(showtime, SHOWTIME_FORMAT_DISPLAY)
        if (eventDate.isValid()) {
            // @ts-ignore
            return eventDate.isSameOrAfter(today, 'date')
        } else {
            const eventDate = dayjs(showtime, SHOWTIME_FORMAT_STORED)
            if (eventDate.isValid()) {
                // @ts-ignore
                return eventDate.isSameOrAfter(today, 'date')
            }
        }
        return false;
    });
    activeVariants.sort((a, b) => {
        const priceA = a.prices[0].amount
        const priceB = b.prices[0].amount
        if (priceA < priceB) {
            return -1
        } else if (priceA > priceB) {
            return 1
        } else {
            const timeA = a.options[0].value
            const timeB = b.options[0].value
            if (timeA < timeB) return -1
            else if (timeA > timeB) return 1
            else return 0
        }
    })
    const inactiveVariants = product.variants.filter(v => !activeVariants.some(av => av.id === v.id))
    return {...product, activeVariants, inactiveVariants} as Product
}

export function getShowtime(product: Product, variant: ProductVariant): string {
    const showtimeOption = product.options.find(o => o.title === 'Showtime')
    let showtimeOptionId: string | undefined
    if (showtimeOption) {
        showtimeOptionId = showtimeOption.id
    }
    if (showtimeOptionId) {
        const showtimeVariantOption = variant.options.find(o => o.option_id === showtimeOptionId)
        if (showtimeVariantOption) {
            return showtimeVariantOption.value
        }
    }
    return variant.title
}