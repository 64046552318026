import type {BaseQueryFn, FetchArgs, FetchBaseQueryError,} from '@reduxjs/toolkit/query'
import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import {addError, clearMessages} from "./messages-slice";
import {AppState, store} from "./store";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const adminUser = (getState() as AppState).user.admin
        if (adminUser) {
            headers.set('Authorization', `Bearer ${adminUser.api_token}`)
        }
        return headers
    },
    credentials: 'include', // This allows server to set cookies
})
export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
    > = async (args, api, extraOptions) => {
    store.dispatch(clearMessages())
    let result = await baseQuery(args, api, extraOptions)
    // @ts-ignore
    if (result.data?.error) {
        // @ts-ignore
        store.dispatch(addError(result.data?.error.errors))
        window.scrollTo(0, 0)
    }
    return result
}