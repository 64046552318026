import '../styles/globals.css'
import {FC, ReactElement, ReactNode, useEffect} from "react";
import type {AppProps} from 'next/app'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {useAppDispatch, wrapper} from "../store/store";
import {fetchCart} from "../store/cart-slice";
import {useRouter} from "next/router";
import {clearMessages} from "../store/messages-slice";
import {NextPage} from "next";
import {Provider} from "react-redux";
import Script from "next/script";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

declare global {
    interface Window { dataLayer: any; }
}

const AppWrapper: FC<AppPropsWithLayout> = ({ Component, pageProps }: AppPropsWithLayout) => {
    const { store, props } = wrapper.useWrappedStore(pageProps);
    const getLayout = Component.getLayout ?? ((page) => page)
    return <>
        <Script id={"google-tag-manager"} strategy={"afterInteractive"}>
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID as string}');
                <!-- End Google Tag Manager -->
            `}
        </Script>
        <Provider store={store}><App>{getLayout(<Component {...props} />)}</App></Provider>
    </>
}

const App = ({children}: any) => {
    const router = useRouter()
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(fetchCart())
    }, [])


    useEffect(() => {
        const handleRouteChange = () => {
            dispatch(clearMessages())
        }
        router.events.on('routeChangeStart', handleRouteChange)
    }, [router.events, dispatch]);
    return <>{children}</>
}
export default AppWrapper
