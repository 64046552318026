import {Region} from "@medusajs/medusa";
import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "./store";

export interface RegionState {
    currentRegion: Region | undefined
    regionList: Region[]
}

const initialState: RegionState = {
    currentRegion: undefined,
    regionList: []
};

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegion(state, action) {
            state.currentRegion = action.payload
        },
        setRegionList(state, action) {
            state.regionList = action.payload
        }
    }
});

export const selectCurrentRegion = (state: AppState): Region | undefined => state.region.currentRegion;
export const selectRegionList = (state: AppState): Region[] => state.region.regionList;


export default regionSlice.reducer