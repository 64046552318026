import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Medusa from "@medusajs/medusa-js";
import {AppState} from "./store";
import {Cart} from "@medusajs/medusa";

const medusa = new Medusa({baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string, maxRetries: 3});

export const addLineItemToCart = createAsyncThunk('medusa/carts/add-line-item', async (lineItem: any) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.lineItems.create(cartId, lineItem);
    return addShippingOption(response.cart)
})

async function addShippingOption(cart: Partial<Cart>) {
    const shippingOptions = (await medusa.shippingOptions.listCartOptions(cart.id as string)).shipping_options
    const serviceFeesOption = shippingOptions.find((so) => so.provider_id === "service-fees")
    if (serviceFeesOption) {
        const response = await medusa.carts.addShippingMethod(cart.id as string, {option_id: serviceFeesOption.id})
        return response.cart
    }
    return cart
}

export const updateLineItemQuantity = createAsyncThunk('medusa/carts/update-line-item-quantity', async (update: any, thunkAPI) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.lineItems.update(cartId, update.lineItemId, {quantity: update.quantity})
    return addShippingOption(response.cart)
})

export const deleteLineItemFromCart = createAsyncThunk('medusa/carts/delete-line-item', async (lineItemId: string, thunkAPI) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.lineItems.delete(cartId, lineItemId)
    return addShippingOption(response.cart)
})

export const updateCustomerIdInCart = createAsyncThunk('medusa/carts/update-customer-id', async (customerId: string, thunkAPI) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.update(cartId, {
        customer_id:customerId
    })
    return response.cart
})

export const addPromoCode = createAsyncThunk('medusa/carts/add-promo-code', async (code: string, thunkAPI) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.update(cartId, {
        discounts: [{code}]
    })
    return addShippingOption(response.cart)
})

export const removePromoCode = createAsyncThunk('medusa/carts/remove-promo-code', async (code: string, thunkAPI) => {
    const cartId = localStorage.getItem("cartId") as string;
    const response = await medusa.carts.deleteDiscount(cartId, code)
    return addShippingOption(response.cart)
})


export const completeCart = createAsyncThunk('medusa/carts/complete', async (): Promise<Cart> => {
    const cartId = localStorage.getItem("cartId") as string;
    try {
        await medusa.carts.complete(cartId)
    } catch (e) {
        console.log("e is ", e)
    }
    localStorage.removeItem("cartId")

    return await createOrFetchCart();
})

export const fetchCart = createAsyncThunk('medusa/carts/fetch', async (): Promise<Cart>  => {
    return await createOrFetchCart();
})

const createOrFetchCart = async (): Promise<Cart>  => {
    const cartId = localStorage.getItem("cartId");
    if (!cartId) {
        try {
            const response = await medusa.carts.create()
            localStorage.setItem("cartId", response.cart.id)
            return response.cart as Cart;
        } catch (e) {
            throw e;
        }
    } else {
        try {
            const response = await medusa.carts.retrieve(cartId)
            return response.cart as Cart;
        } catch (e) {
            try {
                const response = await medusa.carts.create()
                localStorage.setItem("cartId", response.cart.id)
                return response.cart as Cart;
            } catch (e) {
                throw e;
            }
        }
    }
}

export interface CartState {
    cart: Partial<Cart>;
    stripeSecretKey: string | undefined
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | null | undefined
}

const initialState: CartState = {
    cart: {items: [], total: 0, subtotal: 0},
    stripeSecretKey: undefined,
    status: 'idle',
    error: null
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        replaceCart(state, action) {
            state.cart = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                // @ts-ignore TODO
                state.cart = action.payload
            })
            .addCase(fetchCart.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addLineItemToCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addLineItemToCart.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.cart = action.payload
            })
            .addCase(addLineItemToCart.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(completeCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(completeCart.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(completeCart.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(deleteLineItemFromCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(deleteLineItemFromCart.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(deleteLineItemFromCart.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateLineItemQuantity.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateLineItemQuantity.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(updateLineItemQuantity.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(updateCustomerIdInCart.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(updateCustomerIdInCart.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(updateCustomerIdInCart.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(addPromoCode.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(addPromoCode.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(addPromoCode.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(removePromoCode.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(removePromoCode.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.cart = action.payload
            })
            .addCase(removePromoCode.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
});

export const selectCartState = (state: AppState): Partial<Cart> => state.cart.cart;
export const selectCartId = (state: AppState): string | undefined => state.cart.cart.id;

export default cartSlice.reducer

export const {replaceCart} = cartSlice.actions;



