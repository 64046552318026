import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cartReducer from "./cart-slice";
import messagesReducer from "./messages-slice";
import regionReducer from "./region-slice";

import { createWrapper } from "next-redux-wrapper";
import {useDispatch} from "react-redux";
import {apiSlice} from "./apiSlice";
import userReducer from "./user-slice";

export const store = configureStore({
    reducer: {
        'cart': cartReducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
        'messages': messagesReducer,
        'user': userReducer,
        'region': regionReducer
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true,
})
const makeStore = () => store;

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    AppState,
    unknown,
    Action
    >;
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const wrapper = createWrapper<AppStore>(makeStore);